import { getUnreadCountTotal } from '~/api/noticeCenter'

export const useUnreadCount = () => {
  const unreadCount = ref(0)
  const getCount = () => {
    getUnreadCountTotal().then((res) => {
      unreadCount.value = res.data.totalUnreadCount
    })
  }

  // 未读消息数展示
  const unreadCountDisplay = computed(() => {
    const value = unreadCount.value as number
    if (value > 0 && value <= 99) {
      return unreadCount.value
    } else if (value > 99) {
      return '99+'
    }
    return ''
  })

  return {
    getCount,
    unreadCount,
    unreadCountDisplay
  }
}
